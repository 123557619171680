import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LockIcon from "@material-ui/icons/Lock";
import Popper from '@material-ui/core/Popper';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Box} from "@material-ui/core";


export function UpsellPopper() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'upsell-popper' : undefined;

    return (
        <Button
            className="nav-button"
            aria-describedby={id} type="button" onClick={handleClick}
            startIcon={<LockIcon/>}
            variant="outlined"
            size="large">
            User History
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Box p={1}>
                    <Card>
                        <CardContent>
                            <Typography variant="subtitle1">Upgrade to see user's recently viewed
                                items.</Typography>
                        </CardContent>
                        <CardActions>
                            <a
                                href="https://www.shoptelligence.com/sales-assistant-upgrade/"
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button size="small" color="primary">Upgrade</Button>
                            </a>
                        </CardActions>
                    </Card>
                </Box>
            </Popper>
        </Button>
    );
}
