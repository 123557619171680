import React from "react";
import {Redirect, useParams} from "react-router-dom";
import {CookieName} from "../constants";
import {getSalesAssistantConfig} from "../api/recentlyViewedApiRequests";
import {Loading} from "./loading";
import {Box, Grid, Paper, Typography} from "@material-ui/core";

interface Props {
    setupCode: any
}

interface State {
    setupCode: string,
    redirect: string,
}

class Setup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            setupCode: typeof this.props.setupCode === "string" ? this.props.setupCode : "",
            redirect: "",
        };
    }

    componentDidMount() {
        let {setupCode} = this.state;
        if (setupCode) {
            localStorage.setItem(CookieName.clientCode, setupCode);
            getSalesAssistantConfig(setupCode).then(
                response => this.setState({redirect: "../"})
            ).catch(
                reason => this.setState({redirect: "../config"})
            );
        } else {
            localStorage.removeItem(CookieName.clientCode);
            this.setState({redirect: '../config'});
        }
    }

    render() {
        if (this.state.redirect !== "") {
            return (<Redirect to={this.state.redirect}/>);
        }
        return (
            <Box py={5}>
                <Paper elevation={3}>
                    <Grid>
                        <Box py={5}>
                            <Grid container item justify="center">
                                <Loading/>
                            </Grid>
                        </Box>
                        <Box py={3}>
                            <Typography align="center" variant="h5">Loading...</Typography>
                        </Box>
                    </Grid>
                </Paper>
            </Box>
        );
    }
}


export function SetupPage() {
    let {setupCode} = useParams();
    return (
        <div>
            <Setup setupCode={setupCode}/>
        </div>
    );
}
