import DeleteIcon from '@material-ui/icons/Delete';
import React from "react";
import SaveIcon from '@material-ui/icons/Save';
import {Box, Button, Grid, Paper, TextField, Typography} from "@material-ui/core"
import {CookieName} from "../constants";
import {Redirect} from 'react-router-dom'
import {getSalesAssistantConfig} from "../api/recentlyViewedApiRequests";


interface Props {
    location: Location
}

interface State {
    redirect: string,
    error: boolean
}

export class ConfigPage extends React.Component<Props, State> {
    private clientCode: string = localStorage.getItem(CookieName.clientCode) || "";
    private storeId: string = localStorage.getItem(CookieName.storeId) || "";
    private salesId: string = localStorage.getItem(CookieName.salesId) || "";

    constructor(props: any) {
        super(props);

        this.state = {redirect: '', error: false};
        this.saveConfig = this.saveConfig.bind(this);
    }

    saveConfig() {
        localStorage.setItem(CookieName.storeId, this.storeId || "");
        localStorage.setItem(CookieName.salesId, this.salesId || "");
        if (this.clientCode) {
            getSalesAssistantConfig(this.clientCode).then(
                response => {
                    localStorage.setItem(CookieName.clientCode, this.clientCode);
                    this.setState({error: false});
                    this.setState({redirect: '../'})
                }
            ).catch(
                reason => {
                    this.setState({error: true});
                }
            )
        } else {
            this.setState({error: true});
        }
    }

    render() {
        if (this.state.redirect !== '') {
            return <Redirect to={this.state.redirect}/>;
        }

        let clientCodeInput = this.state.error ?
            <TextField
                required
                error
                helperText="Invalid Client Code"
                id="client-code"
                label="Client Code"
                variant="outlined"
                fullWidth={true}
                defaultValue={this.clientCode}
                onChange={event => this.clientCode = event.target.value}
            /> :
            <TextField
                required
                id="client-code"
                label="Client Code"
                variant="outlined"
                fullWidth={true}
                defaultValue={this.clientCode}
                onChange={event => this.clientCode = event.target.value}
            />
        return (
            <Box py={10}>
                <Grid container spacing={0}>
                    <Grid item xs/>
                    <Grid item xs={10} sm={8} md={6} lg={4}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <form noValidate autoComplete="off">
                                    <Box m={2}><Typography align="center" variant="h5">Settings</Typography></Box>
                                    <Box m={2}>
                                        {clientCodeInput}
                                    </Box>
                                    <Box m={2}>
                                        <TextField
                                            id="store-id"
                                            label="Store ID"
                                            variant="outlined"
                                            fullWidth={true}
                                            defaultValue={this.storeId}
                                            onChange={event => this.storeId = event.target.value}
                                        />
                                    </Box>
                                    <Box m={2}>
                                        <TextField
                                            id="sales-id"
                                            label="Sales Associate ID"
                                            variant="outlined"
                                            fullWidth={true}
                                            defaultValue={this.salesId}
                                            onChange={event => this.salesId = event.target.value}
                                        />
                                    </Box>
                                    <Box m={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Button
                                                    className="config-button"
                                                    variant="contained"
                                                    color="default"
                                                    size="medium"
                                                    fullWidth={true}
                                                    startIcon={<DeleteIcon/>}
                                                    onClick={() => this.setState({redirect: '../'})}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    className="config-button"
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    fullWidth={true}
                                                    startIcon={<SaveIcon/>}
                                                    onClick={this.saveConfig}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </form>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs/>
                </Grid>
            </Box>
        );
    }
}
