import Fab from '@material-ui/core/Fab';
import QrReader from 'react-qr-reader';
import React from "react";
import queryString from 'query-string'
import {Box, Grid, Typography} from "@material-ui/core"
import {CookieName} from "../constants";
import {Loading} from "./loading";
import {Redirect} from "react-router-dom"
import {getSalesAssistantConfig} from "../api/recentlyViewedApiRequests";
import SettingsIcon from '@material-ui/icons/Settings';

interface Props {
}

interface State {
    salesId: string,
    background: string | null,
    logo: string | null,
    redirect: string,
    error: boolean,
    fetchingConfig: boolean
}

const uuidRegex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

export class ScanPage extends React.Component<Props, State> {
    constructor(props: object) {
        super(props);
        this.state = {
            salesId: localStorage.getItem(CookieName.salesId) || "",
            background: null,
            logo: null,
            redirect: '',
            fetchingConfig: true,
            error: false
        }
        this.onError = this.onError.bind(this)
        this.onScan = this.onScan.bind(this)
    }

    onError(err: any) {
        if (err) {
            console.log(err);
        }
    }

    onScan(data: string | null) {
        if (data !== null) {
            console.log(data);
            let match = data.match(uuidRegex);
            if (match) {
                let query = queryString.stringify({uuid: data});
                this.setState({redirect: `./collect?${query}`});
            }
        }
    }

    componentDidMount() {
        if (this.state.fetchingConfig) {
            let clientCode = localStorage.getItem(CookieName.clientCode);
            if (clientCode === null) {
                return this.setState({redirect: "./config"})
            }
            getSalesAssistantConfig(clientCode).then(
                response => {
                    this.setState({
                        fetchingConfig: false,
                        background: response.data.data.background,
                        logo: response.data.data.logo,
                    })
                }
            ).catch(
                reason => {
                    this.setState({fetchingConfig: false, error: true})
                }
            )
        }
    }

    render() {
        if (this.state.redirect !== '') {
            return (<Redirect to={this.state.redirect}/>);
        }
        if (this.state.fetchingConfig) {
            return (
                <Box py={5}>
                    <Grid>
                        <Box py={5}>
                            <Grid container item justify="center">
                                <Loading/>
                            </Grid>
                        </Box>
                        <Box py={3}>
                            <Typography align="center" variant="h5">Loading...</Typography>
                        </Box>
                    </Grid>
                </Box>
            );
        }
        const body = document.querySelector('body');
        if (body) {
            body.style.backgroundImage = `url("${this.state.background}")`;
        }
        let button = (
            <Fab variant="extended" aria-label="settings" onClick={() => this.setState({redirect: "./config"})}>
                <Box style={{textTransform: "none"}} px={1}>Setup Profile</Box>
                <SettingsIcon/>
            </Fab>
        );
        if (this.state.salesId !== "") {
            button = (
                <Fab variant="extended" aria-label="settings" onClick={() => this.setState({redirect: "./config"})}>
                    <Box style={{textTransform: "none"}} px={1}>{this.state.salesId}</Box>
                    <SettingsIcon/>
                </Fab>
            );
        }
        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xs>

                    </Grid>
                    <Grid item xs>
                        <div className="client-logo" style={{backgroundImage: `url("${this.state.logo}")`}}/>
                    </Grid>
                    <Grid item xs>

                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs/>
                    <Grid item xs={10} lg={6}>
                        <div className="camera-container">
                            <QrReader className="camera" delay={300} onError={this.onError} onScan={this.onScan}
                                      showViewFinder={false}/></div>
                    </Grid>
                    <Grid item xs/>
                </Grid>
                <Box p={2} className="setting-button">
                    {button}
                </Box>
            </div>
        );
    }
}
