import axios, {AxiosResponse} from "axios";
import {ApiEnv, ApiEnvHost, DefaultHost} from "../constants";
import {recentlyViewedApiModelTypes} from "./recentlyViewedApiModelTypes";

const API_ENV = process.env.REACT_APP_API_ENV || ApiEnv.pro;
const API_HOST = ApiEnvHost.get(API_ENV) || DefaultHost;

const client = axios.create({
    timeout: 10000,
    validateStatus: function (status) {
        return status === 200;
    }
});

export function collectQrCodeScanEvent(
    event: recentlyViewedApiModelTypes.requestModels.QrCodeScanEvent
): Promise<AxiosResponse<recentlyViewedApiModelTypes.responseModels.ClientCoupon>> {
    return client.post(`${API_HOST}/v1/instore/qr-scan`, event)
}


export function getSalesAssistantConfig(setupCode: string): Promise<AxiosResponse<recentlyViewedApiModelTypes.responseModels.SalesAssistantConfig>> {
    return client.post(`${API_HOST}/v1/instore/config`, {setupCode: setupCode})
}
