import * as RecentlyViewedApiRequests from '../api/recentlyViewedApiRequests';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import React from "react";
import queryString from 'query-string'
import {Box, Button, Grid, Grow, Paper, Typography} from "@material-ui/core";
import {CookieName, ErrorMessages} from "../constants"
import {Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {UpsellPopper} from "./upsellButton";

interface Location {
    search: string
}

interface Props {
    location: Location
}

interface State {
    redirect: string,
    saved: Boolean,
    status: number,
    couponCode: string | null,
    couponText: string | null
}


export class CollectPage extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            redirect: "",
            saved: false,
            status: 0,
            couponCode: null,
            couponText: null
        };
    }

    componentDidMount() {
        let {uuid = ''} = queryString.parse(this.props.location.search);
        if (uuid === null || Array.isArray(uuid)) {
            // todo: display error
            return this.setState({redirect: '../'})
        }
        let clientCode = localStorage.getItem(CookieName.clientCode);
        if (!clientCode) {
            return this.setState({redirect: '../config'})
        }
        let storeId = localStorage.getItem(CookieName.storeId) || "";
        let salesId = localStorage.getItem(CookieName.salesId) || "";

        RecentlyViewedApiRequests.collectQrCodeScanEvent(
            {
                clientCode: clientCode,
                uuid: uuid,
                storeId: storeId,
                salesId: salesId
            }
        ).then((response) => {
            console.log(response.data);
            let {couponCode, couponText} = response.data.data;
            this.setState({couponCode: couponCode, couponText: couponText, saved: true, status: 200});
        }).catch((reason) => {
            if (reason.response && reason.response.status) {
                this.setState({status: reason.response.status});
            } else {
                this.setState({status: -1})
            }
        })
    }

    render() {
        if (this.state.redirect !== '') {
            return (<Redirect to='../'/>);
        }

        let icon = <CircularProgress style={{fontSize: 80}}/>;
        let statusCode = this.state.status;
        if (statusCode !== 0) {
            if (statusCode === 200) {
                icon = <Grow in={true} timeout={500}><CheckCircleOutlineIcon style={{fontSize: 80}}/></Grow>;
            } else {
                icon = <Grow in={true} timeout={500}><ErrorIcon style={{fontSize: 80}}/></Grow>;
            }
        }
        let coupon = null;
        let  {couponCode, couponText} = this.state;
        if (couponCode) {
            coupon = <Box py={3}>
                <Typography align="center" variant="h5">Your coupon code is:</Typography>
                <Typography align="center" variant="h4">{couponCode}</Typography>
                <Typography align="center" variant="h5">{couponText}</Typography>
            </Box>
        }

        return (
            <Box py={10}>
                <Grid container spacing={0}>
                    <Grid item xs/>
                    <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
                        <Paper elevation={3}>
                            <Box py={3}>
                                <Typography align="center" variant="h4">Thank you!</Typography>
                            </Box>
                            <Box py={2}>
                                <Typography align="center">
                                    {icon}
                                </Typography>
                            </Box>
                            <Typography align="center">
                                {ErrorMessages.get(this.state.status)}
                            </Typography>
                            {coupon}
                            <Grid container>
                                <Grid item sm={6} xs={12}>
                                    <Box pb={3}>
                                        <Typography align="center">
                                            <Button
                                                className="nav-button"
                                                onClick={() => {
                                                    this.setState({redirect: '../'})
                                                }}
                                                startIcon={<ArrowBackIcon/>}
                                                variant="outlined"
                                                size="large">
                                                Scan QR Code
                                            </Button>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Box pb={3}>
                                        <Typography align="center">
                                            <UpsellPopper/>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs/>
                </Grid>
            </Box>
        );
    }
}
