import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {CollectPage} from "./components/collectPage";
import {ScanPage} from "./components/scanPage"
import {ConfigPage} from "./components/configPage"
import {SetupPage} from "./components/setupPage";

class App extends React.Component {
    render() {
        const basename = process.env.PUBLIC_URL || '';
        return (
            <Router basename={basename}>
                <Switch>
                    <Route exact path="/config" component={ConfigPage} />
                    <Route exact path="/collect" component={CollectPage}/>
                    <Route exact path="/"><ScanPage/></Route>
                    <Route path="/:setupCode" children={<SetupPage />} />
                </Switch>
            </Router>
        );
    }
}


export default App;
